<template>
  <main>
    <section>
      <div class="max-w-screen-lg mx-auto">
        <div class="mx-4">
          <h2 class="text-3xl text-body-4 my-12 font-medium">
            Přidání nemovitosti
          </h2>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">
              Typ nemovitosti
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <div
                v-for="propertyType in $const.PROPERTY_TYPES"
                :key="propertyType.id"
              >
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="radio1"
                    :value="propertyType.id"
                    v-model="property.type"
                    @click="typeHandler(propertyType)"
                    checked
                  />
                  <span class="ml-2">{{ propertyType.name }}</span>
                </label>
              </div>
            </div>
          </div>

          <div
            v-if="submitted && !$v.property.type.required"
            class="font-medium text-sm text-red-500"
          >
            {{ $t("ErrorMessages.FieldIsRequired") }}
          </div>

          <div v-if="flat" class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">
              Číslo bytové jednotky
            </h2>
            <input
              v-mask="flatMask"
              style="width: 128px"
              type="text"
              :class="inputCss"
              v-model="property.flatNumber"
            />
          </div>

          <div
            v-if="submitted && flatNumberError"
            class="font-medium text-sm text-red-500"
          >
            {{ $t("ErrorMessages.FieldIsRequired") }}
          </div>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">Dispozice</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <div
                v-for="disposition in $const.PROPERTY_DISPOSITIONS"
                :key="disposition.id"
              >
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="radio2"
                    :value="disposition.id"
                    @click="dispositionHandler(disposition)"
                    v-model="property.disposition"
                    checked
                  />
                  <span class="ml-2">{{ disposition.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="submitted && !$v.property.disposition.required"
            class="font-medium text-sm text-red-500"
          >
            {{ $t("ErrorMessages.FieldIsRequired") }}
          </div>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 font-bold">
              Stav nemovitosti
            </h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <div
                v-for="propertyState in $const.PROPERTY_STATES"
                :key="propertyState.name"
              >
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="radio3"
                    :value="propertyState.id"
                    v-model="property.state"
                    checked
                  />
                  <span class="ml-2">{{ propertyState.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="submitted && !$v.property.state.required"
            class="font-medium text-sm text-red-500"
          >
            {{ $t("ErrorMessages.FieldIsRequired") }}
          </div>

          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12 mb-2"
          >
            <div class="col-span-1 md:col-span-2">
              <label class="text-15 text-body-4 mt-12 font-bold"
                >Ulice a číslo popisné</label
              >
              <div
                :style="`left: ${manual.x + 40}px;`"
                class="absolute font-semibold text-sm p-4 w-48 z-50 rounded-md text-body-1 text-center bg-white border-2 border-body-1"
                v-if="manual.infoOpen"
              >
                {{ manual.info }}
              </div>
              <span
                @mouseleave="manual.infoOpen = false"
                @mouseenter="addressManual(manual.info)"
                class="select-none ml-2 cursor-help text-body-1 font-bold"
              >
                ?</span
              >
              <input
                type="text"
                @blur="handleAddressField()"
                @click="onChangeAutocompleteInput(property.address)"
                :class="[
                  inputCss,
                  (submitted && !$v.property.address.required) ||
                  (submitted &&
                    this.errorMessage === $t('ErrorMessages.BadAddress') || this.errorMessage === $t('ErrorMessages.ClickOnAddress'))
                    ? 'border-red-500'
                    : '',
                ]"
                v-model="property.address"
                @input="onChangeAutocompleteInput(property.address)"
              />
              <!-- ADDRESS AUTOCOMPLETE API -->
              <div
                v-if="autoCompleteResponse.length"
                class="absolute-container"
                :class="
                  autoCompleteResponse[0].address.street &&
                  autoCompleteResponse[0].address.houseNumber
                    ? 'ok-border'
                    : 'no-border'
                "
              >
                <div
                  class="address-container"
                  v-for="item in autoCompleteResponse"
                  :key="item.locationId"
                >
                  <span
                    v-if="item.address.houseNumber"
                    @click="writeAddressHandler(item.address)"
                    class="address"
                  >
                    <div
                      class="pl-1.5 pr-1.5"
                      v-if="item.address.street && item.address.houseNumber"
                    >
                      {{ item.address.street }}
                    </div>
                    <div class="pr-1.5" v-if="item.address.houseNumber">
                      {{ item.address.houseNumber }},
                    </div>
                    <div class="pr-1.5 font-medium" v-if="item.address.city">
                      {{ item.address.city }},
                    </div>
                    <div class="pr-1.5 font-semibold" v-if="item.address.city">
                      {{ item.address.postalCode }}
                    </div>
                  </span>
                  <span
                    v-if="item.address.street && !item.address.houseNumber"
                    @click="writeAddressHandler(item.address)"
                    class="address"
                  >
                    <div class="pl-1.5 pr-1.5" v-if="item.address.street">
                      {{ item.address.street }}
                    </div>
                    <div class="pr-1.5" v-if="item.address.houseNumber">
                      {{ item.address.houseNumber }},
                    </div>
                    <div class="pr-1.5 font-medium" v-if="item.address.city">
                      {{ item.address.city }},
                    </div>
                    <div class="pr-1.5 font-semibold" v-if="item.address.city">
                      {{ item.address.postalCode }}
                    </div>
                  </span>
                </div>
              </div>
              <!--^ ADDRESS AUTOCOMPLETE API ^-->
              <div
                v-if="submitted && !$v.property.address.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
              <div
                v-if="this.errorMessage === $t('ErrorMessages.BadAddress') || this.errorMessage === $t('ErrorMessages.ClickOnAddress')"
                class="font-medium text-sm text-red-500"
              >
                {{ this.errorMessage }}
              </div>
            </div>

            <div v-if="showCityAndZipCode" class="col-span-1">
              <label class="text-15 text-body-4 mt-12 font-bold">Město</label>
              <input
                type="text"
                :class="[
                  noTypeInput,
                  (submitted && !$v.property.city.required) ||
                  (submitted &&
                    this.errorMessage === $t('ErrorMessages.BadCity'))
                    ? 'border-red-500'
                    : '',
                ]"
                v-model="property.city"
                readonly
              />
              <div
                v-if="submitted && !$v.property.city.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
              <div
                v-if="this.errorMessage === $t('ErrorMessages.BadCity')"
                class="font-medium text-sm text-red-500"
              >
                {{ this.errorMessage }}
              </div>
            </div>

            <div v-if="showCityAndZipCode" class="col-span-1">
              <label class="text-15 text-body-4 mt-12 font-bold">PSČ</label>
              <input
                v-mask="mask"
                :class="[
                  noTypeInput,
                  submitted && !$v.property.zipCode.required
                    ? 'border-red-500'
                    : '',
                ]"
                v-model="property.zipCode"
                readonly
              />
              <div
                v-if="submitted && !$v.property.zipCode.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
            </div>
          </div>
          <div class="pb-2 flex" v-if="withoutHouseNumber">
            <div>
              <Info :color="1" />
            </div>
            <span
              style="line-height: 24px"
              class="text-red-500 text-md select-none font-semibold pl-2"
              >Vaše nemovitost nemá popisné číslo!
            </span>
          </div>
          <div>
            <HereMap v-if="openMap" :center="center" :zoom="zoom" />
          </div>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">
              Vlastnictví
            </h2>
            <div class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3">
              <div
                v-for="ownership in $const.PROPERTY_OWNERSHIPS"
                :key="ownership.name"
              >
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="radio4"
                    :value="ownership.id"
                    v-model="property.ownership"
                    checked
                  />
                  <span class="ml-2">{{ ownership.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="submitted && !$v.property.ownership.required"
            class="font-medium text-sm text-red-500"
          >
            {{ $t("ErrorMessages.FieldIsRequired") }}
          </div>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">Něco navíc</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <div
                v-for="additional in $const.PROPERTY_ADDITIONALS"
                :key="additional.id"
              >
                <label class="inline-flex items-center">
                  <input
                    class="cursor-pointer"
                    type="checkbox"
                    :value="additional.id"
                    v-model="property.additional"
                    @click="inputHandler(additional.name)"
                  />
                  <div class="pl-4 cursor-pointer select-none">
                    {{ additional.name }}
                  </div>
                  <span
                    v-if="
                      additional.name === 'Balkón' ||
                      additional.name === 'Lodžie'
                    "
                    @mouseleave="manual.open = !manual.open"
                    @mouseenter="dispositionManual(additional)"
                    class="select-none ml-2 cursor-help text-body-1 font-bold"
                  >
                    ?</span
                  >
                </label>
              </div>
              <!-- Manual -->
              <div
                :style="`left: ${manual.x + 40}px;`"
                class="absolute pt-2"
                v-if="manual.open"
              >
                <!-- Lodžie -->
                <div
                  v-if="manual.item.name === 'Lodžie'"
                  class="bg-blue-100 p-4 text-lg rounded-md font-bold text-center"
                >
                  <span class="uppercase">{{ manual.item.name }}</span>
                  <span>
                    <img
                      class="rounded-md mt-2"
                      width="280"
                      height="280"
                      src="../../../public/images/loggia.jpg"
                      :alt="manual.item.id"
                    />
                  </span>
                </div>
                <!-- Lodžie -->
                <div
                  v-if="manual.item.name === 'Balkón'"
                  class="bg-blue-100 p-4 text-lg rounded-md font-bold text-center"
                >
                  <span class="uppercase">{{ manual.item.name }}</span>
                  <span>
                    <img
                      class="rounded-md mt-2"
                      width="280"
                      height="280"
                      src="../../../public/images/balcony.png"
                      :alt="manual.item.id"
                    />
                  </span>
                </div>
              </div>
              <!--^ Manual ^-->
            </div>
          </div>

          <div class="mt-12 flex">
            <Info />
            <span
              style="line-height: 24px"
              class="text-gray-500 text-md text-body-1 select-none pl-2"
              >Rozloha balkónu, lodžie, garáže či terasy je zahrnuta v rozloze
              nemovitosti.</span
            >
            <div
              :style="`left: ${manual.x + 40}px;`"
              class="absolute font-semibold text-sm p-4 w-48 z-50 rounded-md text-body-1 text-center bg-white border-2 border-body-1"
              v-if="manual.exampleOpen"
            >
              {{ manual.exampleInfo }}
            </div>
            <span
              @mouseleave="manual.exampleOpen = false"
              @mouseenter="exampleHandler(manual.exampleInfo)"
              class="select-none ml-2 cursor-help text-body-1 font-bold"
            >
              ?</span
            >
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <div class="col-span-1">
              <label class="text-15 text-body-4 mt-12 font-bold"
                >Užitná plocha nemovitosti<span
                  class="text-gray-500 text-sm pl-2"
                  >(m²)</span
                ></label
              >
              <input
                type="number"
                :class="[
                  inputCss,
                  submitted && !$v.property.usableAreaApartment.required
                    ? 'border-red-500'
                    : '',
                ]"
                v-model="property.usableAreaApartment"
              />
              <div
                v-if="submitted && !$v.property.usableAreaApartment.required"
                class="font-medium text-sm text-red-500"
              >
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
            </div>
            <div v-if="balcony || loggia" class="col-span-1">
              <label class="text-15 text-body-4 mt-12 font-bold"
                >Užitná plocha balkónu/lodžie<span
                  class="text-gray-500 text-sm pl-2"
                  >(m²)</span
                ></label
              >
              <input
                type="number"
                :class="inputCss"
                v-model="property.usableAreaBalcony"
              />
            </div>
            <div v-if="cellar" class="col-span-1">
              <label class="text-15 text-body-4 mt-12 font-bold"
                >Užitná plocha sklepu
                <span class="text-gray-500 text-sm pl-2">(m²)</span></label
              >
              <input
                type="number"
                :class="inputCss"
                v-model="property.usableAreaCellar"
              />
            </div>
          </div>

          <div class="flex flex-row pt-16">
            <div>
              <Info />
            </div>
            <span class="text-gray-500 text-md text-body-1 select-none pl-2">
              Tento text bude součástí inzerátu. Doporučujeme v něm vyzdvihnout
              všechny klady nemovitosti a věty rozvinout tak, aby působily
              profesionálně – díky tomu se vyhnete konfrontaci s profesionálními
              makléři.</span
            >
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-15 text-body-4 mt-12 font-bold">
                Stav a dispozice
                <span class="text-gray-500 text-sm pl-2"
                  >(minimálně 30 znaků)</span
                >
                <div
                  :style="`left: ${manual.x + 40}px;`"
                  :class="quoteStyle"
                  v-if="popisky.popis_nemovitosti.open"
                >
                  {{
                    property.type === 2
                      ? popisky.popis_nemovitosti.title_house
                      : popisky.popis_nemovitosti.title_flat
                  }}
                </div>
                <span
                  @mouseleave="popisky.popis_nemovitosti.open = false"
                  @mouseenter="popiskyHandler('popis_nemovitosti', true)"
                  class="select-none ml-2 cursor-help text-body-1 font-bold"
                >
                  ?</span
                >
              </h2>
            </div>
            <div>
              <h2
                class="text-xs text-body-1 mt-12 font-bold float-none md:float-right cursor-pointer"
                @click="patternHandler('popis_nemovitosti')"
              >
                Vzor
              </h2>
            </div>
          </div>
          <div class="mt-3">
            <textarea
              rows="4"
              :class="[
                inputCss,
                this.errorMessage === $t('ErrorMessages.NoDescription') ||
                this.errorMessage === $t('ErrorMessages.BadDescription')
                  ? 'border-red-500'
                  : '',
              ]"
              v-model="property.propertyDescription"
            ></textarea>
            <div
              v-if="this.errorMessage === $t('ErrorMessages.NoDescription')"
              class="font-medium text-sm text-red-500"
            >
              {{ this.errorMessage }}
            </div>
            <div
              v-if="this.errorMessage === $t('ErrorMessages.BadDescription')"
              class="font-medium text-sm text-red-500"
            >
              {{ this.errorMessage }}
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-15 text-body-4 mt-8 font-bold">
                Okolí a občanská vybavenost
                <div
                  :style="`left: ${manual.x + 40}px;`"
                  :class="quoteStyle"
                  v-if="popisky.popis_okoli_a_obcanske_vybavenosti.open"
                >
                  {{
                    property.type === 2
                      ? popisky.popis_okoli_a_obcanske_vybavenosti.title_house
                      : popisky.popis_okoli_a_obcanske_vybavenosti.title_flat
                  }}
                </div>
                <span
                  @mouseleave="
                    popisky.popis_okoli_a_obcanske_vybavenosti.open = false
                  "
                  @mouseenter="
                    popiskyHandler('popis_okoli_a_obcanske_vybavenosti', true)
                  "
                  class="select-none ml-2 cursor-help text-body-1 font-bold"
                >
                  ?</span
                >
              </h2>
            </div>
            <div>
              <h2
                class="text-xs text-body-1 mt-8 font-bold float-none md:float-right cursor-pointer"
                @click="patternHandler('popis_okoli_a_obcanske_vybavenosti')"
              >
                Vzor
              </h2>
            </div>
          </div>
          <div class="mt-3">
            <textarea
              :class="inputCss"
              rows="4"
              v-model="property.surroundingDescription"
            ></textarea>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-15 text-body-4 mt-8 font-bold">
                Konstrukce a technický stav
                <div
                  :style="`left: ${manual.x + 40}px;`"
                  :class="quoteStyle"
                  v-if="popisky.popis_z_venkovniho_pohledu.open"
                >
                  {{
                    property.type === 2
                      ? popisky.popis_z_venkovniho_pohledu.title_house
                      : popisky.popis_z_venkovniho_pohledu.title_flat
                  }}
                </div>
                <span
                  @mouseleave="popisky.popis_z_venkovniho_pohledu.open = false"
                  @mouseenter="
                    popiskyHandler('popis_z_venkovniho_pohledu', true)
                  "
                  class="select-none ml-2 cursor-help text-body-1 font-bold"
                >
                  ?</span
                >
              </h2>
            </div>
            <div>
              <h2
                class="text-xs text-body-1 mt-8 font-bold float-none md:float-right cursor-pointer"
                @click="patternHandler('popis_z_venkovniho_pohledu')"
              >
                Vzor
              </h2>
            </div>
          </div>
          <div class="mt-3">
            <textarea
              :class="inputCss"
              rows="4"
              v-model="property.outsideViewDescription"
            ></textarea>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-15 text-body-4 mt-8 font-bold">
                Energetické a doplňující informace
                <div
                  :style="`left: ${manual.x + 40}px;`"
                  :class="quoteStyle"
                  v-if="
                    popisky.popis_z_energetickych_a_doplnujicich_informaci.open
                  "
                >
                  {{
                    property.type === 2
                      ? popisky.popis_z_energetickych_a_doplnujicich_informaci
                          .title_house
                      : popisky.popis_z_energetickych_a_doplnujicich_informaci
                          .title_flat
                  }}
                </div>
                <span
                  @mouseleave="
                    popisky.popis_z_energetickych_a_doplnujicich_informaci.open = false
                  "
                  @mouseenter="
                    popiskyHandler(
                      'popis_z_energetickych_a_doplnujicich_informaci',
                      true
                    )
                  "
                  class="select-none ml-2 cursor-help text-body-1 font-bold"
                >
                  ?</span
                >
              </h2>
            </div>
            <div>
              <h2
                class="text-xs text-body-1 mt-8 font-bold float-none md:float-right cursor-pointer"
                @click="
                  patternHandler(
                    'popis_z_energetickych_a_doplnujicich_informaci'
                  )
                "
              >
                Vzor
              </h2>
            </div>
          </div>
          <div class="mt-3">
            <textarea
              :class="inputCss"
              rows="4"
              v-model="property.energyAndAdditionalDescription"
            ></textarea>
          </div>

          <div class="block">
            <h2 class="text-15 text-body-4 mt-12 mb-2 font-bold">
              Energetická třída
              <div
                :style="`left: ${manual.x + 40}px;`"
                class="absolute font-semibold text-sm p-4 w-48 z-50 rounded-md text-body-1 text-center bg-white border-2 border-body-1"
                v-if="manual.energyInfoOpen"
              >
                {{ manual.energyInfo }}
              </div>
              <span
                @mouseleave="manual.energyInfoOpen = false"
                @mouseenter="energyInfoHandler(manual.energyInfo)"
                class="select-none ml-2 cursor-help text-body-1 font-bold"
              >
                ?</span
              >
            </h2>

            <div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4">
              <div
                v-for="energyClass in $const.ENERGY_CLASSES"
                :key="energyClass.name"
              >
                <label class="inline-flex items-center">
                  <input
                    type="radio"
                    class="form-radio"
                    name="radio5"
                    :value="energyClass.name"
                    v-model="property.energyClass"
                    checked
                  />
                  <span class="ml-2">{{ energyClass.name }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 mt-10 mb-24">
            <div>
              <button
                @click="$router.go(-1)"
                class="border border-body-3 hover:bg-body-3 hover:text-white rounded-md h-14 px-8 text-body-3 text-15 font-bold focus:outline-none"
              >
                Krok zpět
              </button>
            </div>
            <div class="text-left md:text-right">
              <button
                class="modal-open bg-body-3 hover:opacity-70 rounded-md px-8 h-14 text-white text-15 font-bold focus:outline-none"
                @click="save()"
              >
                Vytvořit nemovitost
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="patternDescriptionToggle">
        <div v-if="property.type === 2 && patternDescriptionToggle">
          <PropertyDescriptionModal
            v-on:close="closeForm($event)"
            :description="description"
            :type="property.type"
            v-on:=""
          />
        </div>
        <div v-if="property.type === 1 && patternDescriptionToggle">
          <PropertyDescriptionModal
            v-on:close="closeForm($event)"
            :description="description"
            :type="property.type"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { FETCH_PROPERTIES } from "@/store/main/constants";
import { LOGIN } from "@/store/user/constants";
import { required } from "vuelidate/lib/validators";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import axios from "axios";
import { findAddress } from "../../services/rest/services";
import { findCoordinates } from "../../services/rest/services";
import HereMap from "../../components/map/HereMap.vue";
import PropertyDescriptionModal from "../../components/modals/PropertyDescriptionModal.vue";
import Info from "../../components/svg/Info.vue";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 5,
});

const currencyPriceMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 16,
});

const flatMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 6,
});

export default {
  components: {
    HereMap,
    PropertyDescriptionModal,
    Info,
  },
  data() {
    return {
      description: "",
      patternDescriptionToggle: false,
      patternDescriptions: {
        popis_nemovitosti: {
          dum:
            "Prodám hezký dům 4+1 s terasou o užitné ploše cca 160m² a pozemkem o velikosti 525m². Dům stojí v klidné uličce nedaleko historického centra města Kouřim s krásným výhledem do údolí a okolní zeleně. Dům prošel celkovou rekonstrukcí v roce 2002. Je celkově ve výborném technickém stavu: kvalitně odizolován, v létě je v domě příjemný chládek a v zimě výborně drží teplo. Má nové elektrické rozvody, stoupačky, rozvody topení, dlažbu, dřevěné podlahy, schodiště a je zabezpečen alarmem. Nemovitost má novou fasádu, dřevěná eurookna a novou střechu. Nemovitost je částečně podsklepena a napojena na kanalizaci i vodovod.. Dominantou tohoto domu je velký obývací pokoj s knihovnou a krbem, který je propojen s kuchyní, jídelnou a zimní zahradou. Z té je možný vstup na terasu. Koupelna je prostorná s vanou a sprchovým koutem. Na patře se nacházejí tři pokoje a malá hala s příjemným posezením. V suterénu se nachází stylový vinný sklípek. Zahrada je udržovaná a poskytuje dostatek soukromí. K dispozici garáž, která je přímo propojena s domem. Vše působí kompaktně a uklidňujícím dojmem, navozuje pocit klidu a bezpečí.",
          byt:
            "Prodám hezký s citem zrekonstruovaný byt 3+1 o ploše 66m² + 5m² lodžie v 8. patře panelového domu na Praze 4 - Chodov, ul. Benkova. Jde o moderně působící byt, který prošel zdařilou rekonstrukcí v minulém roce: dřevěné, masivní dveře, dlažba, obložená koupelna s vanou, nové vstupní bezpečnostní dveře, úložný systém na míru v předsíni a kuchyni, plastová okna, vymalováno... Praktická dispozice s centrální vstupní chodbou z níž lze samostatnými vchody vstoupit do všech pokojů. Dominantou bytu je velkorysý, prosluněný obývací pokoj propojen s lodžií a s kuchyní, která je zařízena kuchyňskou linkou se spotřebiči a posezením. Lodžie je prostorná, zasklená s volným výhledem na západní stranu do klidné ulice s zeleným parkem.",
        },
        byt:
          "Prodám hezký s citem zrekonstruovaný byt 3+1 o ploše 66m² + 5m² lodžie v 8. patře panelového domu na Praze 4 - Chodov, ul. Benkova. Jde o moderně působící byt, který prošel zdařilou rekonstrukcí v minulém roce: dřevěné, masivní dveře, dlažba, obložená koupelna s vanou, nové vstupní bezpečnostní dveře, úložný systém na míru v předsíni a kuchyni, plastová okna, vymalováno... Praktická dispozice s centrální vstupní chodbou z níž lze samostatnými vchody vstoupit do všech pokojů. Dominantou bytu je velkorysý, prosluněný obývací pokoj propojen s lodžií a s kuchyní, která je zařízena kuchyňskou linkou se spotřebiči a posezením. Lodžie je prostorná, zasklená s volným výhledem na západní stranu do klidné ulice s zeleným parkem.",
        popis_okoli_a_obcanske_vybavenosti: {
          dum:
            "Je zde kompletní občanská vybavenost, obchody, lékařské středisko, škola, školka a nádraží. Město často pořádá zajímavé kulturní akce a v blízkých rozsáhlých lesích je mnoho možností k rekreaci (houby, kolo, ryby atd.). Výborná dopravní dostupnost, rychlé a pohodlné spojení do Kolína 20 km, nebo do Prahy 45 km (40 min autem, nebo vlakem). Ideální místo k bydlení, nejen pro rodinu s dětmi. Volný dohodou.",
          byt:
            "Okolí vhodné i pro rodinu s malými dětmi, v ulici se nachází dětské hřiště, školka a supermarket. Dalším z benefitů je blízkost parku, který je protkán cyklostezkami a velkými plochami zeleně, které jsou oblíbeným místem pro sportování a odpočinek. Lokalita nabízí kompletní občanskou vybavenost a plnohodnotnou městskou infrastrukturu, v pěší vzdálenosti metro a stanice autobusů.",
        },
        popis_z_venkovniho_pohledu: {
          dum:
            "Dům je cihlový ve výborném technickém stavu, byl zateplen, má novou fasádu, výtah, stoupačky a opravenou střechu.",
          byt:
            "Dům je panelový ve výborném technickém stavu, byl zateplen, má novou fasádu, výtah, stoupačky a opravenou střechu.",
        },
        popis_z_energetickych_a_doplnujicich_informaci: {
          dum:
            "Topení a ohřev vody zabezpečen novým kombinovaným plynovým kotlem. Dle dohody možnost ponechání většiny zařízení, volný dohodou.",
          byt:
            "Topení a ohřev vody zabezpečen kombinovaným plynovým kotlem. Dle dohod možnost ponechání většiny zařízení, volný dohodou. Osobní vlastnictví, možnos financování hypotečním úvěrem.",
        },
      },
      withoutHouseNumber: false,
      openMap: true,
      center: {
        lat: 50.05,
        lng: 14.5,
      },
      showCityAndZipCode: false,
      zoom: 7,
      autoCompleteResponse: [],
      defaultPlace: {},
      noTypeInput:
        "w-full mt-2 px-3 py-2 border-gray-300 text-gray-800 border rounded-md focus:outline-none bg-gray-100 cursor-default",
      inputCss:
        "w-full mt-2 px-3 py-2 border-gray-300 text-gray-800 border rounded-md focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
      typeName: "",
      deispositionName: "",
      errorMessage: "",
      submitted: false,
      priceMask: currencyPriceMask,
      flatMask: flatMask,
      mask: currencyMask,
      flat: false,
      flatNumberError: false,
      property: {
        energyClass: "G",
        flatNumber: "",
        userId: "",
        user_id: "",
        propertyPrice: null,
        address: "",
        zipCode: "",
        city: "",
        usableAreaApartment: "",
        usableAreaBalcony: "",
        usableAreaCellar: "",
        disposition: null,
        type: null,
        state: null,
        ownership: null,
        additional: [],
        descriptions: [],
        propertyDescription: "",
        dispositionViewDescription: "",
        surroundingDescription: "",
        outsideViewDescription: "",
        energyAndAdditionalDescription: "",
      },
      balcony: false,
      loggia: false,
      cellar: false,
      quoteStyle: `absolute font-semibold text-sm p-4 w-96 z-50 text-1xl rounded-md text-body-1 text-center bg-white border-2 border-body-1`,
      manual: {
        energyInfo:
          "Pokud nemáte stanoveno odbornou osobou, prosím, použijte třídu G",
        energyInfoOpen: false,
        infoOpen: false,
        flatInfoOpen: false,
        exampleInfo:
          "Pokud celková rozloha nemovitosti je 105m² a balkón má 5m², celková rozloha je stále 105m².",
        exampleOpen: false,
        info:
          "Pro správné vyhledání Vaší nemovitosti vyplňte ulici, číslo popisné a potvrďte celou adresu z výběru",
        flatFloatInfo: "Pro desetinné místo použíjte tečku.",
        x: "",
        y: "",
        open: false,
        item: "",
        address: "../../../public/images/",
      },
      popisky: {
        popis_nemovitosti: {
          id: "popis_nemovitosti",
          open: false,
          title_house:
            "Stručnou charakteristiku domu (nový, po rekonstrukci, původní stav), stavební materiál ze kterého byl postaven, s uvedením dispozice (2+1,3+1…), velikosti (m2), velikost pozemku, počet pater domu, jestli je podsklepen, má garáž, terasu a uvést lokalitu kde se nachází (město, obec, část Prahy, včetně uvedení ulice). Popis dispozice zvlášť pro přízemí a patro domu, technický stav (podlahy,střecha,rozvody, okna, kuch.linka), včetně jeho napojení na sítě (elektro, plyn, vodovod, studna, kanalizace…). U rekonstrukcí uvést kdy a kterými dům prošel. V popisu vyzdvihnout (zdůraznit) co jsou přednosti tohoto domu, včetně jeho orientace (svět. strany, zeleň, centrum, klidná ulice…).",
          title_flat:
            "Stručnou charakteristiku bytu (nový, po rekonstrukci, původní stav) s uvedením dispozice (2+1,3+1…), velikosti (m2) , patra domu v jakém se nachází a lokality kde to je (město, včetně uvedení ulice). Popis technického stavu bytu ( podlahy, byt. jádro, rozvody, okna, kuch. linka…), včetně rekonstrukcí i částečných kterými byt prošel. V popisu vyzdvihnout (zdůraznit) co jsou přednosti tohoto bytu, včetně jeho orientace (svět. strany, zelený park, klidná ulice…)",
        },
        popis_okoli_a_obcanske_vybavenosti: {
          id: "popis_okoli_a_obcanske_vybavenosti",
          open: false,
          title_house:
            "Popis okolí a občanské vybavenosti v nejbližší vzdálenosti (obchody, škola, školka, nemocnice, parky, dětská hřiště…) a dopravní infrastrukturu v blízkosti (bus, tramvaj, vlak…) a dostupnost do nejbližšího většího města.",
          title_flat:
            "Popis okolí a občanské vybavenosti v nejbližší vzdálenosti (obchody, škola, školka, nemocnice, parky, dětská hřiště, možnosti parkování…) a dopravní infrastrukturu v blízkosti (metro, bus, tramvaj, vlak…)",
        },
        popis_z_venkovniho_pohledu: {
          id: "popis_z_venkovniho_pohledu",
          open: false,
          title_house:
            "Stručný popis stavu a druhu domu ze kterého je postaven (panel, cihla, skeletový, dřevo, včetně rekonstrukcí které proběhli (zateplení, plast. okna, nová střecha, stoupačky,…)",
          title_flat:
            "Stručný popis stavu a druhu domu ve kterém se byt nachází (panel, cihla, skeletový, včetně rekonstrukcí které proběhli (zateplení, plast. okna, nová střecha, stoupačky, výtah…)",
        },
        popis_z_pohledu_dispozice: {
          id: "popis_z_pohledu_dispozice",
          open: false,
          title_house: `Popis dispozice zvlášť pro přízemí a patro domu, technický stav (podlahy,střecha,rozvody,
okna, kuch.linka), včetně jeho napojení na sítě (elektro, plyn, vodovod, studna, kanalizace…).`,
          title_flat: `Dále
stručný popis stavu domu ve kterém se byt nachází, včetně rekonstrukcí které proběhli
(zateplení, plast. okna, nová střecha, stoupačky, výtah…).
          `,
        },
        popis_z_energetickych_a_doplnujicich_informaci: {
          id: "popis_z_energetickych_a_doplnujicich_informaci",
          open: false,
          title_house:
            "Uvést typ topení a ohřevu vody (ústřední dálkové, centrální kotel: plynový, elektrický- tuhé palivo, lokální plynové – elektrické- tuhá paliva, podlahové topení, jiné…) Dále uvést jestli se nemovitost prodává včetně zařízení, jestli je možnost financování hypotékou a termín uvolnění nemovitosti.",
          title_flat:
            "Uvést typ topení (ústřední dálkové, centrální kotel: plynový, elektrický- tuhé palivo, lokální plynové – elektrické- tuhá paliva, podlahové topení, jiné…) Dále uvést jestli se nemovitost prodává včetně zařízení, možnost sklepu, komory, v případě družstevního bytu výši nesplacené anuity, zmínit výši poplatků jen pokud jsou nízké, jestli je možnost financování hypotékou a termín uvolnění nemovitosti.",
        },
      },
      context: {
        input: "",
      },
    };
  },
  computed: {
    ...mapState("main", ["properties"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    closeForm(e) {
      this.patternDescriptionToggle = e;
    },
    patternHandler(pattern) {
      this.patternDescriptionToggle = true;
      if (pattern.includes("popis_nemovitosti")) {
        this.description = this.patternDescriptions.popis_nemovitosti;
      } else if (pattern.includes("popis_okoli_a_obcanske_vybavenosti")) {
        this.description = this.patternDescriptions.popis_okoli_a_obcanske_vybavenosti;
      } else if (pattern.includes("popis_z_venkovniho_pohledu")) {
        this.description = this.patternDescriptions.popis_z_venkovniho_pohledu;
      } else if (
        pattern.includes("popis_z_energetickych_a_doplnujicich_informaci")
      )
        this.description = this.patternDescriptions.popis_z_energetickych_a_doplnujicich_informaci;
    },
    async onChangeAutocompleteInput(address) {
      if (address) {
        this.autoCompleteResponse = await findAddress(
          address.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        );
      }
    },
    closeAddressHandler() {
      this.autoCompleteResponse = [];
    },
    handleAddressField() {
      setTimeout(() => {
        this.autoCompleteResponse = [];
      }, 200);

      if (!this.showCityAndZipCode) {
        this.errorMessage = this.$t('ErrorMessages.ClickOnAddress');
      }
    },
    async writeAddressHandler(e) {
      this.withoutHouseNumber = false;
      if (!e.houseNumber) {
        this.withoutHouseNumber = true;
      }
      this.showCityAndZipCode = false;
      this.openMap = false;
      this.autoCompleteResponse = [];
      if (e.city) {
        this.property.city = e.city;
      }
      if (e.street) {
        this.property.address = e.street;
      }
      if (e.houseNumber) {
        this.property.address = e.houseNumber;
      }
      if (e.street && e.houseNumber) {
        this.property.address = e.street + " " + e.houseNumber;
      }
      if (e.postalCode) {
        this.property.zipCode = e.postalCode.split(" ");
      }
      const addressNormalize = {
        houseNumber: e.houseNumber,
        street: e.street.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        postalCode: e.postalCode,
      };
      const coordinates = await findCoordinates(
        JSON.stringify(addressNormalize)
      );
      this.center = {
        lat: coordinates.Latitude,
        lng: coordinates.Longitude,
      };
      this.zoom = 18;
      this.openMap = true;
      this.showCityAndZipCode = true;
      this.errorMessage = "";
    },
    dispositionManual(e) {
      this.manual.x = event.clientX;
      this.manual.open = true;
      this.manual.item = e;
    },
    addressManual(e) {
      this.manual.x = event.clientX;
      this.manual.y = event.clientY;
      this.manual.infoOpen = true;
      this.manual.info = e;
    },
    flatInfo(e) {
      this.manual.x = event.clientX;
      this.manual.y = event.clientY;
      this.manual.flatInfoOpen = true;
      this.manual.flatFloatInfo = e;
    },
    energyInfoHandler(e) {
      this.manual.x = event.clientX;
      this.manual.y = event.clientY;
      this.manual.energyInfoOpen = true;
      this.manual.energyInfo = e;
    },
    exampleHandler(e) {
      this.manual.x = event.clientX;
      this.manual.y = event.clientY;
      this.manual.exampleOpen = true;
      this.manual.exampleInfo = e;
    },
    popiskyHandler(e) {
      this.manual.x = event.clientX;
      this.manual.y = event.clientY;
      Object.values(this.popisky).forEach((popis) => {
        if (popis.id === e) {
          popis.open = true;
        }
      });
    },
    inputHandler(e) {
      if (e === "Sklep") {
        return (this.cellar = !this.cellar);
      }
      if (e === "Balkón") {
        return (this.balcony = !this.balcony);
      }
      if (e === "Lodžie") {
        return (this.loggia = !this.loggia);
      }
      return;
    },
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
    ...mapActions("main", {
      fetchProperties: FETCH_PROPERTIES,
    }),
    ...mapMutations("main", ["currentProperty"]),
    dispositionHandler(e) {
      this.dispositionName = e.name;
    },
    typeHandler(e) {
      this.typeName = e.name;
      if (e.id === 1) {
        this.flat = true;
      } else this.flat = false;
    },
    async save() {
      try {
        if (this.withoutHouseNumber) {
          return;
        }
        this.flatNumberError = false;
        this.errorMessage = "";
        this.submitted = true;
        // Street must includes name, space, number
        if (this.property.address !== "") {
          const regex_street_space_number = /[^A-Za-z0-9]+/;
          if (!regex_street_space_number.test(this.property.address)) {
            return (this.errorMessage = this.$t("ErrorMessages.BadAddress"));
          }
          // Remove spaces before first word and extra spacing between words
          this.property.address = this.property.address
            .replace(/\s+/g, " ")
            .trim();
        }
        // Description can´t be ampty
        if (this.property.propertyDescription === "") {
          window.scrollTo(0, 1440);
          return (this.errorMessage = this.$t("ErrorMessages.NoDescription"));
        }
        if (this.property.propertyDescription !== "") {
          if (this.property.propertyDescription.length < 30) {
            window.scrollTo(0, 1440);
            return (this.errorMessage = this.$t(
              "ErrorMessages.BadDescription"
            ));
          }
        }
        // if flat => flat number required !
        if (this.property.type === 1) {
          if (this.property.flatNumber === "") {
            window.scrollTo(0, 0);
            return (this.flatNumberError = true);
          }
        }
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".text-red-500")
              .getBoundingClientRect();
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
            );
            return;
          });
          return;
        } 
        else if (!this.$v.$invalid) {
          this.property.userId = this.user.id;
          this.property.user_Id = this.user.id;

          // Create a property into db
          const createProperty = await axios.post(
            `${process.env.VUE_APP_STRAPI_API_URL}/properties`,
            {
              name:
                this.typeName +
                "-" +
                this.dispositionName +
                "-" +
                this.property.address.replace(/\s/g, "-"),
              address: this.property.address,
              zipCode: this.property.zipCode,
              city: this.property.city,
              usableAreaApartment: this.property.usableAreaApartment,
              usableAreaBalcony: this.property.usableAreaBalcony,
              usableAreaCellar: this.property.usableAreaCellar,
              dispositionViewDescription: this.property
                .dispositionViewDescription,
              propertyDescription: this.property.propertyDescription,
              surroundingDescription: this.property.surroundingDescription,
              outsideViewDescription: this.property.outsideViewDescription,
              energyAndAdditionalDescription: this.property
                .energyAndAdditionalDescription,
              propertyDisposition: this.property.disposition,
              propertyType: this.property.type,
              propertyState: this.property.state,
              propertyOwnership: this.property.ownership,
              propertyAdditional: this.property.additional.toString(),
              propertyPrice: this.property.propertyPrice,
              userId: this.property.userId,
              user_id: this.property.userId,
              latitude: this.center.lat,
              longitude: this.center.lng,
              flatNumber: this.property.flatNumber,
              energyClass: this.property.energyClass,
            },
            {
              headers: {
                Authorization: `Bearer ${this.user.jwt}`,
              },
            }
          );
          if (createProperty.status === 200) {
            // After create a property fetch all properties from db
            const getProperties = await axios.get(
              `${process.env.VUE_APP_STRAPI_API_URL}/properties`,
              {
                headers: {
                  Authorization: `Bearer ${this.user.jwt}`,
                },
              }
            );
            if (getProperties.status === 200) {
              // Save properties into store
              this.$store.commit("main/SET_PROPERTIES", getProperties.data);
              // Save created current property into store | localstorage
              this.$store.commit("main/SET_CURRENT_PROPERTY", this.property);
              // Save state of created property
              localStorage.setItem("created", true);
              localStorage.getItem(
                this.property,
                JSON.stringify(this.$store.state.main.currentProperty)
              );
              this.$router.push("/nemovitost");
            }
          }
        }
      } catch (error) {
        return error;
      }
    },
    addDescription() {
      this.property.descriptions.push({
        name: "Popis " + (this.property.descriptions.length + 1),
      });
    },
  },
  validations: {
    property: {
      type: { required },
      disposition: { required },
      state: { required },
      ownership: { required },
      address: { required },
      zipCode: { required },
      city: { required },
      usableAreaApartment: { required },
    },
  },
};
</script>

<style scoped>
.absolute-container {
  position: relative;
  font-size: 14px;
  top: -4px;
  display: block;
  margin: 0px;
  padding: 0px;
  width: 100%;
  z-index: 1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.address-container {
  z-index: 2;
  display: flex;
  width: 100%;
}

.address {
  z-index: 2;
  display: flex;
  flex-direction: row;
  background-color: rgb(243, 244, 254);
  padding: 4px;
  width: 100%;
}

.address:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.ok-border {
  border-bottom: 1px solid blue;
  border-left: 1px solid blue;
  border-right: 1px solid blue;
}

.no-border {
  border: none;
}
</style>
